@charset "UTF-8";
/*******************************************************************************
* COPYRIGHT NOTICE © 2020 Infosys Limited, Bangalore, India. All Rights
* Reserved. Infosys believes the information in this document is accurate as of
* its publication date; such information is subject to change without notice.
* Infosys acknowledges the proprietary rights of other companies to the
* trademarks, product names and such other intellectual property rights
* mentioned in this document. Except as expressly permitted, neither this
* documentation nor any part of it may be reproduced, stored in a retrieval
* system, or transmitted in any form or by any means, electronic, mechanical,
* printing, photocopying, recording or otherwise, without the prior permission
* of Infosys Limited and/or any named intellectual property rights holders
* under this document.
*******************************************************************************/

html {
    font-size: 10px;
}

body {
    background-color: #F8FBFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.main-headings {
    font-size: 2rem;
    font-weight: 800;
    line-height: 3.7rem;
    letter-spacing: 0em;
    text-align: left;
    color: #43607B;
}

.sub-heading1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.4rem;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
}

.sub-heading2 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
}

.btn {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.4rem;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
}

.common-toolbar input{
    border: none;
    padding: 4px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgb(133 134 135 / 32%);
    background: rgb(243, 248, 255);
}

.common-toolbar mat-select{
    border: none;
    height: 24px;
    background: #f3f8ff;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgb(133 134 135 / 32%);
}

.api-response-panel .mat-expansion-panel-body {
    padding: 0rem 1rem 1rem;
}

.primary-btn {
    height: 50px;
    width: 160px;
    border-radius: 7px;
    background: #1971FB;
    box-shadow: 0px 0px 6px 0px rgb(24 111 247 / 55%);
    cursor: pointer;
}

.secondary-btn{
    display: flex;
    border-radius: 7px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgb(24 111 247 / 30%);
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.white-btn {
    color:#1e90ff;
    border: 1px solid #1e90ff;
    background-color: #ffffff;
}
.blue-btn {
    color:#ffffff;
    background-color: #1e90ff;
    border:0px;
}

.sec-btn {
    height: fit-content;
    color: #1971FB;
    font-size: 1.2rem;
    background: none;
    border: none;
    padding: unset;
    font-weight: bolder;
}

.primary-headings {
    color: #43607B;
}

.sec-headings-icons {
    color: #707070;
}

.sec-text {
    color: #aeaeae;
}

.primary-bg-color {
    background-color: #1971FB;
}

.logo-font-color {
    color: #9EC2FB;
}

.logo-bg-color {
    background-color: #9EC2FB;
}

.text-field {
    height: 40px;
    width: 321px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px rgb(70, 118, 193, 0.25);
}

.text-field-err {
    height: 40px;
    width: 321px;
    border-radius: 10px;
    border: 1px solid #FF8282;
    box-shadow: 0px 0px 6px 0px rgb(70, 118, 193, 0.25);
    font-size: 1.6rem;
    font-weight: 600;
    background: #FF8282;
}

.main-bg-color {
    background-color: #F8FBFF;
}

.error-style {
    color: #ff0000;
    font-size: x-small;
}

.alerts {
    background-color: #FF8282
}

.progress-bar-inactive {
    background-color: #F2F2F2;
}

.progress-bar-active {
    background-color: #74CD63;
}

.ratings {
    color: #FFA406;
}

.icons {
    color: #FFFFFF;
}

.tile {
    border-radius: 25px;
    color: #f8fbff;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgb(70 118 193 / 25%);
}

button.remove-default-style {
    background: none;
    color: white;
    opacity: 1;
    border: none;
    font-size: 3rem;
    padding: 15px 0;
    cursor: pointer;
}

.toolbar-container .mat-expansion-panel-header{
    box-shadow: 0px 0px 4px 0px #9bb4db;
}

button.remove-default-style:hover {
    opacity: 1;
    background-color: #e0e0e0;
    color: #656565;
}

.font-size-sm {
    font-size: 1.1rem;
    font-weight: bold;
}

.font-size-lg {
    font-size: 1.2rem;
}

.vjs-big-play-button {
    display: none;
    visibility: hidden;
}

.video-js .vjs-control-bar {
    display: flex !important;
}

.toolbar-minimize {
    position: absolute;
    top: -2px;
    left: 5px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #454545;
}

.annotation-added-card.mat-snack-bar-container{
    display: flex;
    background: #f5a004;
    color: rgb(0, 0, 0);
    font-weight: bold;
    height: 3rem;
    width: 26rem;
    border-radius: 9px;
    padding: 0.5rem 1.9rem;
    min-height: unset;
    min-width: unset;
    margin: 21rem 0rem 0rem 52.2rem;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
    flex-direction: row;
    justify-content: center;
}

.clipboard-snackbar.mat-snack-bar-container{
    display: flex;
    background: #464646;
    color: rgb(255 255 255);
    height: 3rem;
    width: 26rem;
    border-radius: 9px;
    padding: 0.5rem 1.9rem;
    min-height: unset;
    min-width: unset;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
    flex-direction: row;
    justify-content: center;
}


.annotation-added-card.mat-snack-bar-container button, .clipboard-snackbar.mat-snack-bar-container button{
    background: rgb(68 68 68);
    color: rgb(255 255 255);
}

.annotation-added-card.mat-snack-bar-container .mat-simple-snackbar, .annotation-added-card.mat-snack-bar-container .mat-simple-snackbar-action,
.clipboard-snackbar.mat-snack-bar-container .mat-simple-snackbar, .clipboard-snackbar.mat-snack-bar-container .mat-simple-snackbar-action {
    font-family: Montserrat, "Helvetica Neue", sans-serif;
    font-size: 1.2rem;
}